import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const WeeklyGospelImage = () => (
  <StaticQuery
    query={graphql`
      query WeeklyGospelImageQuery {
        weeklyGospelImage: file(relativePath: { eq: "MottoDiagram.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.weeklyGospelImage.childImageSharp.fluid}
        style={{ maxHeight: '500px' }}
        imgStyle={{ maxHeight: '500px', objectFit: 'contain' }}
      />
    )}
  />
);

export default WeeklyGospelImage;
