import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  Spinner,
} from 'reactstrap';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import PageHeading from '../../components/PageHeading/PageHeading';
import Layout from '../../components/Layout';
import axios from 'axios';
import ReactPlaceholder from 'react-placeholder';
import styles from '../../css/weeklyGospel.module.css';
import TitleBar from '../../components/TitleBar/TitleBar';
import WeeklyGospelImage from '../../components/GatsbyImageComponents/WeeklyGospelImage';
import moment from 'moment';
import UpcomingWeeklyGospel from '../../components/UpcomingWeeklyGospel/UpcomingWeeklyGospel';

import WeeklyGospelVideos from '../../components/WeeklyGospelVideos/WeeklyGospelVideos';
import { useQuery } from 'react-query';

const WeeklyGospel = () => {
  const [upcomingSundayLesson, setUpcomingSundayLesson] = useState(null);

  const getNextSunday = () => {
    const dayINeed = 0; // for Sunday
    const today = moment().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= dayINeed) {
      // then just give me this week's instance of that day
      return moment().isoWeekday(dayINeed);
    } else {
      // otherwise, give me *next week's* instance of that same day
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  };

  const url =
    'https://cors-veym.herokuapp.com/https://cms.veym.net/items/weekly_gospel_lessons?sort=sunday_date&filter[sunday_date][_gt]=2022-08-01';

  const { isLoading, data: gospels } = useQuery(
    'weeklyGospelList',
    async () => {
      const res = await axios.get(url);
      const {
        data: { data },
      } = res;
      setUpcomingSundayLesson(
        data.find(({ sunday_date }) =>
          moment(sunday_date).isSame(getNextSunday(), 'day')
        )
      );
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  console.log(upcomingSundayLesson);

  return (
    <Layout>
      <Helmet
        title="VEYM | Weekly Gospel Lessons"
        meta={[
          {
            name: 'description',
            content:
              'VEYM Weekly Gospel Lessons - Gospel Lessons for All Levels',
          },
        ]}
      />
      <PageHeading>Weekly Gospel Lessons</PageHeading>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/resources">Resources</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Weekly Gospel</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <br />
      {!isLoading && upcomingSundayLesson === undefined ? null : (
        <>
          <TitleBar>Upcoming Sunday Gospel</TitleBar>
          <Container>
            <br />
            {!isLoading && upcomingSundayLesson !== undefined ? (
              <UpcomingWeeklyGospel
                upcomingSundayLesson={upcomingSundayLesson}
                dateString={getNextSunday().format('dddd, MMMM Do, YYYY')}
              />
            ) : (
              <div>
                <Spinner color="secondary" size="lg" />
              </div>
            )}
          </Container>

          <br />
        </>
      )}

      <br />
      <TitleBar>2021-2022 Weekly Scripture Lessons and Activities</TitleBar>
      <Container>
        <br />

        <Row>
          <Col md={6}>
            <p>
              With great excitement, the Curriculum Committee announces its
              publication of the Weekly Scripture Lessons and Activities. With
              your support through the annual membership dues, the National
              Executive Committee will mail the printed booklets at no cost to
              each Chapter. Please provide BCHTU at headquarters@veym.net your
              updated Chapter’s census and contact information.
              <br />
              The Academy Headquarters encourages all Youth-Leaders to read the{' '}
              <a
                href="https://drive.google.com/file/d/0B_R6O41YvUrWR1c3RUhna01fWUE/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youth-Leader Instructional Manual
              </a>{' '}
              to understand the concept and structure behind the development of
              these lessons. The Weekly Scripture Lesson will include the
              following components:
            </p>

            <ol>
              <li>
                <b>Scripture: </b>
                Sunday Gospel presented
              </li>
              <li>
                <b>Virtue: </b>
                One Catholic virtue from the Gospel examined
              </li>
              <li>
                <b>Lesson: </b>
                Key topic (integration of gospel and virtue) discussed
              </li>
              <li>
                <b>Activities: </b>
                <ul>
                  <li>Bible Song</li>
                  <li>Bible Cheer</li>
                  <li>Bible Game</li>
                </ul>
              </li>
              <li>
                <b>Homework: </b>
                Practical tasks to live virtue assigned
              </li>
            </ol>
          </Col>
          <Col md={6}>
            <WeeklyGospelImage />
          </Col>
        </Row>

        <p>
          {' '}
          <a
            href="https://drive.google.com/file/d/0B_R6O41YvUrWR1c3RUhna01fWUE/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Youth-Leader Instructional Manual
          </a>
        </p>
        <hr />
        <p>
          The full booklet for the 2022-2023 school year can be downloaded here:
        </p>
        <ul>
          <li>
            Ngành AN: &nbsp;
            <a
              href="https://veymmainsitestorage.blob.core.windows.net/resources/WGL_AN_2022-2023_C-A.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành TN: &nbsp;
            <a
              href="https://veymmainsitestorage.blob.core.windows.net/resources/WGL_TN_2022-2023_C-A.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành NS: &nbsp;
            <a
              href="https://veymmainsitestorage.blob.core.windows.net/resources/WGL_NS_2022-2023_C-A.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành HS: &nbsp;
            <a
              href="https://veymmainsitestorage.blob.core.windows.net/resources/WGL_HS_2022-2023_C-A.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
        </ul>
      </Container>
      <br />
      <br />

      <br />
      <br />
      <TitleBar>Weekly Lessons</TitleBar>
      <br />
      <Container>
        {isLoading && (
          <div>
            <h2>Loading Weekly Gospel Lessons..</h2>
            <br />
          </div>
        )}
        <ReactPlaceholder
          showLoadingAnimation
          type="text"
          rows={50}
          ready={!isLoading}
        >
          <div className={styles.table}>
            <Table striped>
              <thead>
                <tr>
                  <td>Date</td>
                  <td>Verse</td>
                  <td>Virtue</td>
                  <td>Links</td>
                </tr>
              </thead>
              <tbody>
                {gospels &&
                  gospels.map(
                    ({
                      liturgy_week,
                      sunday_date,
                      bible_source,
                      lesson_title,
                      virtue,
                      an_file,
                      tn_file,
                      ns_file,
                      hs_file,
                    }) => {
                      return (
                        <tr key={`${sunday_date}-${liturgy_week}`}>
                          <td>
                            {moment(sunday_date).format('MMMM Do, YYYY')} <br />
                            {liturgy_week}
                          </td>
                          <td>
                            {bible_source} <br />
                            {lesson_title.replace(/\?/g, `'`)}
                          </td>
                          <td>{virtue}</td>
                          <td>
                            <a
                              href={`https://cms.veym.net/assets/${an_file}`}
                              className={styles.anLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              AN
                            </a>{' '}
                            |
                            <a
                              href={`https://cms.veym.net/assets/${tn_file}`}
                              className={styles.tnLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              &nbsp;TN
                            </a>{' '}
                            |
                            <a
                              href={`https://cms.veym.net/assets/${ns_file}`}
                              className={styles.nsLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              &nbsp;NS
                            </a>{' '}
                            |
                            <a
                              href={`https://cms.veym.net/assets/${hs_file}`}
                              className={styles.hsLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              &nbsp;HS
                            </a>
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </Table>
          </div>
        </ReactPlaceholder>

        <br />
        {/* <TitleBar>Weekly Gospel Videos</TitleBar>
        <Container>
          <br />
          <p>
            To help the youth continue to live the faith and pray while
            homebound due to COVID-19 during the spring and summer of 2020, the
            National Academic Committee prepared supplemental resources to aid
            with the Weekly Gospel Lessons.
          </p>
          <WeeklyGospelVideos />
        </Container>
        <br />
        <br /> */}
        <TitleBar>Previous Weekly Gospel Lessons</TitleBar>
        <br />
        <p>
          The full booklet for the 2021-2022 school year can be downloaded here:
        </p>
        <ul>
          <li>
            Ngành AN: &nbsp;
            <a
              href="https://veymmainsitestorage.blob.core.windows.net/resources/WGL2021-2022_AN.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành TN: &nbsp;
            <a
              href="https://veymmainsitestorage.blob.core.windows.net/resources/WGL2021-2022_TN.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành NS: &nbsp;
            <a
              href="https://veymmainsitestorage.blob.core.windows.net/resources/WGL2021-2022_NS.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành HS: &nbsp;
            <a
              href="https://veymmainsitestorage.blob.core.windows.net/resources/WGL2021-2022_HS.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
        </ul>
        <br />
        <hr />
        <p>
          The full booklet for the 2020-2021 school year can be downloaded here:
        </p>
        <ul>
          <li>
            Ngành AN: &nbsp;
            <a
              href="https://veym.net/resources/weeklygospel/AN-WGL_2020-2021.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành TN: &nbsp;
            <a
              href="https://veym.net/resources/weeklygospel/TN-WGL_2020-2021.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành NS: &nbsp;
            <a
              href="https://veym.net/resources/weeklygospel/NS-WGL_2020-2021.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành HS: &nbsp;
            <a
              href="https://veym.net/resources/weeklygospel/HS-WGL_2020-2021.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
        </ul>
        <br />
        <hr />
        <br />
        <p>
          The full booklet for the 2019-2020 school year can be downloaded here:
        </p>
        <ul>
          <li>
            Ngành AN: &nbsp;
            <a
              href="https://drive.google.com/file/d/1deHbkIKWl2El3V1IMR6aNJc-KlIu9P1g/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành TN: &nbsp;
            <a
              href="https://drive.google.com/file/d/18uD1iv7kN4Gwzdri6utUX7yP9pVzZElr/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành NS: &nbsp;
            <a
              href="https://drive.google.com/file/d/1VLTCdwPgw0Fa6Y9Xy0fD_FG889gf8vcN/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành HS: &nbsp;
            <a
              href="https://drive.google.com/file/d/12X2-lbTkyTJ8RFXvnnuFnm5p980vOjB2/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
        </ul>
        <p>
          The Gospel Lessons corresponding to the dates in the 2019-2020 school
          year can be found in this document:{' '}
          <a
            href="/resources/files/WeeklyGospelTOC_2019-2020.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Table of Contents Insert
          </a>
        </p>
        <br />
        <hr />
        <br />
        <p>
          For the 2018-2019 school year, the Weekly Gospel Lessons are available
          as a full year booklet. You can download them here:
        </p>
        <ul>
          <li>
            Ngành AN: &nbsp;
            <a
              href="https://drive.google.com/file/d/1nGXkfUuLfWrXyaAN7Hbw2aWTaS06dpG2/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành TN: &nbsp;
            <a
              href="https://drive.google.com/file/d/1nJy01gJBTeI2tEpt4m7tV3_L4z_9tRyO/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành NS: &nbsp;
            <a
              href="https://drive.google.com/file/d/1nMeA6dxDYa3uMAZLnFWf2IO3MEApk6xe/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
          <li>
            Ngành HS: &nbsp;
            <a
              href="https://drive.google.com/file/d/1nRvOolNzzPF5h3SSYEYEcm1-Ir5A8X9V/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </li>
        </ul>
      </Container>
      <br />
      <br />
    </Layout>
  );
};

export default WeeklyGospel;

export const frontmatter = {
  title: 'Weekly Gospel Lessons',
  category: 'resources',
  path: '/resources/weeklygospel',
  description: 'Weekly Gospel Lessons',
  tags: 'resource, weekly gospel lessons, tin mung,  ',
};
